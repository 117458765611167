import React, {useState} from "react";

import LogoQci from "../../assets/icons/logo_qci.svg";
import {RegisterPasskeyTile} from "../authentication/register_passkey_tile.jsx";
import {useIntl} from "react-intl"

export function RegisterPasskeyView(props) {
  const intl = useIntl()

  const [completedSteps, setCompletedSteps] = useState(0)

  const stepProps = {
    completed: (c) => (<span className="step-completed">{c}</span>),
    step: (c) => (<span className="step-number">{c}</span>)
  }

  return (
    <div className={"authentication-view register-passkey view " + (props.className ?? "")}>
      <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>

      <h1 className="title-register-passkey title">
        {intl.formatMessage({id: "registerPasskey.title"}, {vendor: (c) => c, product: (copy) => (<span className="elevated">{copy}</span>)})}
      </h1>

      <hr className="help-divider"/>

      <RegisterPasskeyTile registrationToken={props.registrationToken} setCompletedSteps={setCompletedSteps}/>

      <hr className="help-divider"/>

      <p className="help register-passkey">
        {intl.formatMessage({id: "registerPasskey.help"}, {vendor: (c) => c})}
      </p>
      <hr className="help-divider"/>

      <h2 className="steps-header">
        {intl.formatMessage({id: "registerPasskey.help.steps.title"})}
      </h2>
      <p className={"help register-passkey step" + ((completedSteps >= 1) ? " completed" : "")}>
        {intl.formatMessage({id: "registerPasskey.help.steps.1"}, stepProps)}
      </p>
      <p className={"help register-passkey step" + ((completedSteps >= 2) ? " completed" : "")}>
        {intl.formatMessage({id: "registerPasskey.help.steps.2"}, stepProps)}
      </p>
      <p className={"help register-passkey step" + ((completedSteps >= 3) ? " completed" : "")}>
        {intl.formatMessage({id: "registerPasskey.help.steps.3"}, stepProps)}
      </p>
      <p className={"help register-passkey step" + ((completedSteps >= 4) ? " completed" : "")}>
        {intl.formatMessage({id: "registerPasskey.help.steps.4"}, stepProps)}
      </p>

      <hr className="help-divider"/>

      <p className="help register-passkey extra">
        {
          intl.formatMessage({id: "registerPasskey.help.extra"}, {
            google: (label) => (<a className="help-link register-passkey" target="blank" href="https://safety.google/authentication/passkey">{label}</a>),
            ios: (label) => (<a className="help-link register-passkey" target="blank" href="https://support.apple.com/guide/iphone/use-passkeys-to-sign-in-to-apps-and-websites-iphf538ea8d0/ios">{label}</a>),
            macos: (label) => (<a className="help-link register-passkey" target="blank" href="https://support.apple.com/guide/mac-help/create-a-passkey-mchl4af65d1a/mac">{label}</a>),
            microsoft: (label) => (<a className="help-link register-passkey" target="blank" href="https://learn.microsoft.com/windows/security/identity-protection/passkeys">{label}</a>)
          })
        }
      </p>

      {/* <div className="legal-links">
        <a className="legal-link" href="#">{intl.formatMessage({id: "legal.terms.link"})}</a>
        <a className="legal-link" href="#">{intl.formatMessage({id: "legal.privacy.link"})}</a>
      </div> */}

    </div>
  );
}
