import React from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {anyAvailable} from "./any_available.js";
import {DashboardSystemUsageCard} from "./dashboard_system_usage_card.jsx";
import {flatEnvironmentSelector} from "../utility/flat_environment_selector.js";
import {sumMetrics} from "./sum_metrics.js";

export function DashboardSystemsUsageTile() {
  const intl = useIntl();

  const selectAllMetrics = flatEnvironmentSelector("metrics");
  const selectUsersMetrics = createSelector(
    selectAllMetrics,
    s => s.accessControlSession.user.id,
    (am, uid) => am.filter(m => m.user?.id === uid)
  );

  const selectCompilerMetrics = createSelector(
    selectUsersMetrics, um => um.filter(m => m.compiler?.id)
  );
  const selectCompilerSum = createSelector(selectCompilerMetrics, sumMetrics);

  const selectSimulatorMetrics = createSelector(
    selectUsersMetrics, um => um.filter(m => m.simulator?.id)
  );
  const selectSimulatorSum = createSelector(selectSimulatorMetrics, sumMetrics);

  const selectControlServerMetrics = createSelector(
    selectUsersMetrics, um => um.filter(m => m.controlServer?.id)
  );
  const hydrateControlServerMetrics = createSelector(
    selectControlServerMetrics, s => s.controlServers, (csm, cs) => csm.map(
      m => Object.assign({}, m, {controlServer: cs[m.controlServer.id]})
    )
  );
  const selectCompilerAvailable = createSelector(flatEnvironmentSelector("compilers"), anyAvailable);
  const selectSimulatorAvailable = createSelector(flatEnvironmentSelector("simulators"), anyAvailable);

  const usersMetrics = useSelector(selectUsersMetrics);
  const compilerSum = useSelector(selectCompilerSum);
  const compilerAvailable = useSelector(selectCompilerAvailable);
  const simulatorSum = useSelector(selectSimulatorSum);
  const simulatorAvailable = useSelector(selectSimulatorAvailable);
  const controlServerMetrics = useSelector(hydrateControlServerMetrics);

  return (
    <div className="tile dashboard-systems-usage-tile dashboard-section">
      <h2 className="dashboard-systems-usage-title dashboard-section title">
        {intl.formatMessage({id: "dashboard.systemUsage.title"})}
      </h2>
      {(Object.values(usersMetrics).length <= 0) && (
        <div className="dashboard-systems-usage-empty-message">
          {intl.formatMessage({id: "dashboard.systemUsage.empty"})}
        </div>
      )}
      <ul className="dashboard-systems-usage-list">
        {compilerSum["total.count"] > 0 && (
          <li className="dashboard-system-usage-item">
            <DashboardSystemUsageCard metric={compilerSum}
                systemIdentifier="qci.compiler"
                systemAvailable={compilerAvailable}/>
          </li>
        )}
        {simulatorSum["total.count"] > 0 && (
          <li className="dashboard-system-usage-item">
            <DashboardSystemUsageCard metric={simulatorSum}
                systemIdentifier="qci.simulator"
                systemAvailable={simulatorAvailable}/>
          </li>
        )}
        {controlServerMetrics.map(metric => (
          <li className="dashboard-system-usage-item">
            <DashboardSystemUsageCard metric={metric}
                systemIdentifier={metric.controlServer.identifier}
                systemAvailable={metric.controlServer.availableAt}/>
          </li>
        ))}
      </ul>
    </div>
  );
}
