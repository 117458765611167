import React, {useState} from "react";
import {useIntl} from "react-intl"
import {useSelector} from "react-redux";

const FAKE_SENDING_DURATION_MS = 5000;

export function EmailAgreementButton(props) {
  const agreement = props.agreement;

  const intl = useIntl();

  const [sending, setSending] = useState(false);
  const session = useSelector(s => s.accessControlSession);

  const handleSend = async () => {
    setSending(true);
    const sendingTimeout = setTimeout(() => setSending(false), FAKE_SENDING_DURATION_MS);
    try {
      await fetch(session.url + "/api/v2/emails", {
        method: "POST",
        credentials: "include",
        headers: {
          "API-Key": session.key,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          subject: intl.formatMessage({id: `agreements.${agreement.identifier}.email.subject`}),
          body: intl.formatMessage({id: `agreements.${agreement.identifier}.email.body`})
        })
      });
    } catch {
      clearTimeout(sendingTimeout);
      setSending(false);
    }
  };

  let className = "agreement-action button email";
  let messageId = `agreements.${agreement.identifier}.button.label.email`;
  if (sending) {
    className += " sending";
    messageId += ".sending";
  }

  return (
    <button className={className} onClick={handleSend} disabled={sending}>
      {intl.formatMessage({id: messageId})}
    </button>
  );
}
