import React from "react";
import {useSelector} from "react-redux";

import {AcceptAgreementButton} from "./accept_agreement_button.jsx";
import {EmailAgreementButton} from "./email_agreement_button.jsx";
import LogoQci from "../../assets/icons/logo_qci.svg";
import {selectNextUnacceptedAgreement} from "../slice/select_next_unaccepted_agreement.js";
import {SignOutButton} from "../sign_out_button.jsx";
import {useIntl} from "react-intl"

export function AgreementView(props) {
  console.group("AgreementView");
  const intl = useIntl();

  const nextAgreement = useSelector(selectNextUnacceptedAgreement);
  const agreementCopy = intl.formatMessage({id: `agreements.${nextAgreement.identifier}.copy`});
  try {
    return (
      <div className={"agreements-view agreements view " + (props.className ?? "")}>
        <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>

        <h1 className="title-agreement title">
          {intl.formatMessage({id: `agreements.${nextAgreement.identifier}.title`})}
        </h1>

        <hr className="help-divider"/>

        <div className="copy" dangerouslySetInnerHTML={{__html: agreementCopy}}/>

        <hr className="help-divider"/>

        <div className="agreement-actions">
          <SignOutButton className="agreement-action decline"
            messageId={`agreements.${nextAgreement.identifier}.button.label.decline`}/>

          <AcceptAgreementButton agreement={nextAgreement}/>
          <EmailAgreementButton agreement={nextAgreement}/>
        </div>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
