import React from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import LogoQci from "../../assets/icons/logo_qci.svg";
import IconNewWindow from "../../assets/icons/icon_new_window.svg";
import {NavigationButton} from "./navigation_button.jsx";
import {NavigationButtonMenuToggle} from "./navigation_button_menu_toggle.jsx";
import {SearchTile as SearchTile} from "../search_tile.jsx";
import {NavigationButtonSignOut} from "./navigation_button_sign_out.jsx";
import {setUserInterface} from "../slice/user_interface_slice.js";


export function NavigationMenu(props) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const accessControlSession = useSelector(s => s.accessControlSession);
  if (!accessControlSession?.user?.roles) {
    return;
  }

  const aboutOpen = useSelector(s => s.userInterface.aboutOpen);
  const open = useSelector(s => s.userInterface.navigationMenuOpen);
  const selectRestricted = createSelector(
    s => s.accessControlSession?.user?.roles,
    roles => Boolean(roles?.find(r => r.identifier === "Restricted"))
  );
  const restricted = useSelector(selectRestricted);
  const showWIP = useSelector(s => s.preferences?.["features.qwa.wip.show"]);

  const handleProductClick = () => {
    dispatch(setUserInterface({"aboutOpen": !aboutOpen}))
  };

  if (!open) {
    return <NavigationButtonMenuToggle toggleMenuOpen={props.toggleMenuOpen}/>;
  }

  let className = "navigation-menu";
  if (restricted) {
    className += " restricted";
  }

  return (
    <div className="sidebar">
      <NavigationButtonMenuToggle toggleMenuOpen={props.toggleMenuOpen}/>

      <div className={className}>
        <button className="product" onClick={handleProductClick}>
          {intl.formatMessage({id: "navigation.product.button"})}
        </button>

        <div className="navigation-group">
          <SearchTile/>
          <NavigationButton path="/dashboard" label="dashboard"/>
          <NavigationButton path="/jobs" label="jobs"/>
          <NavigationButton path="/control-servers" label="controlServers"
            authorize={[
              {target: "compilers", action: "read"},
              {target: "simulators", action: "read"},
              {target: "controlServers", action: "read"}
            ]}/>
          <NavigationButton path="/reports" label="reports"
            authorize={[{target: "reports", action: "read"}]}
            wip={true}/>
          <a href="https://quantum-circuits-aqumen-docs.readthedocs-hosted.com" target="_blank" className="button navigation documentation">
            {intl.formatMessage({id: "navigation.button.documentation"})}
            <IconNewWindow/>
          </a>
        </div>

        <div className="navigation-group">
          <NavigationButton path="/access-control" label="accessControl"
            authorize={[
              {target: "users", action: "read"},
              {target: "roles", action: "read"},
              {target: "permissions", action: "read"}]}/>
          <NavigationButton path="/users" label="users"
            authorize={[{target: "users", action: "read"}]}/>
          <NavigationButton path="/roles" label="roles"
            authorize={[{target: "roles", action: "read"}]}/>
          <NavigationButton path="/permissions" label="permissions"
            authorize={[{target: "permissions", action: "read"}]}/>
        </div>

        <div className="navigation-group">
          <NavigationButton path="/administration" label="administration"
            authorize={[
              {target: "organizations", action: "read"},
              {target: "reservations", action: ["approve", "reject"]}]}
            wip={true}/>
          <NavigationButton path="/organizations" label="organizations"
            authorize={[{target: "organizations", action: "read"}]}
            wip={true}/>
          <NavigationButton path="/reservations-administer" label="reservationsAdminister"
            authorize={[{target: "reservations", action: ["approve", "reject"]}]}
            wip={true}/>
          <NavigationButton path="/report-usage" label="reportUsage"
            authorize={[{target: "reports", action: "read", targetIdentifier: "usageForAdministration"}]}
            wip={true}/>
        </div>

        <div className="navigation-group">
          <NavigationButton path="/account" label="account"/>
          <NavigationButton path="/passkeys" label="passkeys"/>
          <NavigationButton path="/tokens" label="tokens"/>
          <NavigationButton path="/reservations-self" label="reservationsSelf"/>
          <NavigationButton path="/history" label="history" wip={true}/>
        </div>

        <div className="navigation-logo">
          <a className="navigation-link-qci" href="https://quantumcircuits.com" target="blank">
            <LogoQci aria-label={intl.formatMessage({id: "aria.label.logo.qci"})}/>
          </a>
        </div>

        <NavigationButtonSignOut/>
      </div>
    </div>

  );
}
